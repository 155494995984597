<template>
    <div class="pattern-item bg-white rounded-xl shadow-md hover:shadow-xl transition">

        <h4 class="p-3 flex justify-between">
            <router-link class="font-bold" :to="'/patterns/' + pattern.uuid">
                {{ pattern.name }}
            </router-link>

			<span class="text-gray-400">{{ pattern.products.length }}</span>
        </h4>

		<router-link class="font-bold" :to="'/patterns/' + pattern.uuid">
			<img :src="getImage()" alt="">
		</router-link>

        <div class="p-1">

        </div>

    </div>
</template>

<script>

import { devMode, odbUrl } from "@/config";

export default {

    name: 'PatternItem',

    props: {
        pattern: Object
    },

    methods: {
        getImage() {
			return (devMode)
				? 'https://via.placeholder.com/900x600'
				: `${odbUrl}/storage/catalog/patterns/${this.pattern.uuid}/thumb.jpg`
        }
    },

}

</script>
