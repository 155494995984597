<template>
    <nav aria-label="Breadcrumb">
        <ul class="flex justify-left space-x-4 text-sm text-gray-500">
            <li>
                <router-link to="/" class="font-bold hover:text-amber-400">
                    Головна
                </router-link>
            </li>

            <li v-for="(item, i) in items" :key="i" class="flex justify-left space-x-4">
                <span>/</span>

                <div v-if="isLast(i)" class="cursor-default">
                    {{ item.name }}
                </div>

                <router-link v-else :to="item.path" class="hover:text-amber-400">
                    {{ item.name }}
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'v-breadcrumbs',

    props: {
        items: {
            type: Array,
            required: true,
        },
    },

    methods: {
        isLast(index) {
            return index === this.items.length - 1
        },
    }
}
</script>
