<template>
    <div :class="{ disabled }" class="w-min-12 p-2.5 rounded-md flex justify-center space-x-2 cursor-pointer">
        <mdicon v-if="icon" :name="icon"/> <span v-if="text">{{ text }}</span>
    </div>
</template>

<script>

export default {

    name: 'v-button',

    props: {
        disabled: Boolean,
        text: {
            type: String,
            defalut: null
        },
        icon: {
            type: String,
            defalut: null
        }
    }

}

</script>
