<template>
    <div class="flex-auto group relative">

        <div class="flex justify-between">
            <input class="w-full h-16 pl-4 focus:outline-none"
                v-model="search"
                type="text"
                placeholder="Пошук..."
            >
            <button class="m-0 p-4">
                <mdicon name="magnify"/>
            </button>
        </div>

    </div>
</template>

<script>

export default {
    name: 'NavSearchComponent',

    methods: {
        searchAction() {

        }
    },

    data() {
        return {
            search: ''
        }
    }
}
</script>
