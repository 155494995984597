<template>
    <div class="product-item bg-white rounded-xl shadow-md hover:shadow-xl transition group relative">

        <div class="p-1 flex justify-between space-x-4">
            <h4 class="font-semibold" :title="product.type.name">
                <router-link :to="`/patterns/${patternUUID}/${product.uuid}`">
                    {{ product.type.short + ' ' + product.version }}
                </router-link>
            </h4>

			<span>
				<a class="block p-1" :href="getLink( product.uuid )">
					<mdicon name="folder-outline" size="16"/>
				</a>
			</span>

        </div>

		<div class="product-image relative">
					<div class="absolute top-0 left-0 right-0 bottom-0 bg-black/50 transition invisible group-hover:visible">
			<ul class="w-full p-2 grid grid-cols-2 gap-4 text-white">

				<li class="text-center">
					<a class="block p-2" :href="getLink(product.uuid + '/label.jpg')">
						<mdicon name="image-outline"/>
						<span class="">LBL</span>
					</a>
				</li>

				<li class="text-center">
					<a class="block p-2" :href="getLink(product.uuid + '/source.xsd')">
						<mdicon name="file-document-outline"/>
						<span class="">XSD</span>
					</a>
				</li>

				<li class="text-center">
					<a class="block p-2" :href="getLink(product.uuid + '/source.rtf')">
						<mdicon name="file-document-outline"/>
						<span class="">RTF</span>
					</a>
				</li>

				<li class="text-center">
					<a class="block p-2" :href="getLink(product.uuid + '/print.psd')">
						<mdicon name="file-document-outline"/>
						<span class="">PSD</span>
					</a>
				</li>

			</ul>
		</div>

			<img :src="getImage()" alt="">
		</div>

        <div class="p-1">

		</div>

    </div>
</template>

<script>

import helper from '@/helper'
import { devMode, odbUrl } from '@/config'

export default {

	name: 'ProductItem',

	props: {
		patternUUID: String,
		product: Object
	},

	methods: {
		getImage() {
			return (devMode)
				? 'https://via.placeholder.com/600x900'
				: `${odbUrl}/storage/catalog/products/${this.product.uuid}/thumb.jpg`
		},
		getLink( path ) {
			return helper.getLocalNetworkPathLink( 'products/' + path )
		},

	},

	mounted() {
	},

}

</script>
