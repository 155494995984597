const ERROR_TEXT = {
	IMAGE_REQUIRED: 'Основне зображення не вибрано',
	NAME_LENGTH: 'Довжина назви менша 3 символів',
	AUTHOR_REQUIRED: 'Автор не вказаний',
	TAGS_REQUIRED: 'Вказано менше 2 тегів',
}

exports.createPatternRequest = req => {
	const errors = {}

	if ( !req.image ) {
		errors.image = ERROR_TEXT.IMAGE_REQUIRED
	}

	if ( req.name.length < 3 ) {
		errors.name = ERROR_TEXT.NAME_LENGTH
	}

	if ( req.author_id < 1 ) {
		errors.author = ERROR_TEXT.AUTHOR_REQUIRED
	}

	if ( req.tags.length < 2 ) {
		errors.tags = ERROR_TEXT.TAGS_REQUIRED
	}

	return errors
}

exports.updatePatternRequest = req => {
	const errors = {}

	if ( req.name.length < 3 ) {
		errors.name = ERROR_TEXT.NAME_LENGTH
	}

	if ( req.author_id < 1 ) {
		errors.author = ERROR_TEXT.AUTHOR_REQUIRED
	}

	if ( req.tags.length < 2 ) {
		errors.tags = ERROR_TEXT.TAGS_REQUIRED
	}

	return errors
}
