<template>
    <nav class="w-full bg-white shadow-xl">

        <div class="max-w-screen h-16 flex justify-between mx-auto text-gray-500 mr-4">

            <div class="nav-logo flex gap-4 p-4 bg-amber-500 text-white text-xl font-bold">
                {{ title }}
                <mdicon v-if="icon" :name="icon" size="48" class="-mt-2"/>
            </div>

            <slot name="search"></slot>
            <slot name="menu"></slot>
            <slot name="notification"></slot>
            <slot name="volume"></slot>
            <slot name="user"></slot>

        </div>

		<slot name="loader"/>
    </nav>

</template>

<script>

export default {

    name: 'NavbarComponent',

    props: {
        title: { type: String, required: true },
        icon: { type: String, default: null },
    },

}

</script>
