const DEV = false

const BASE_PROTOCOL = (DEV) ? 'http' : 'https'
const BASE_HOST = (DEV) ? 'localhost' : 'db.harmofemb.com.ua'
const BASE_PORT = (DEV) ? ':8080' : ''
const BASE_URL = `${BASE_PROTOCOL}://${BASE_HOST}${BASE_PORT}`

const API_PROTOCOL = (DEV) ? 'http' : 'https'
const API_HOST = (DEV) ? 'localhost' : 'db-backend.harmofemb.com.ua'
const API_PORT = (DEV) ? ':8000' : ''
const API_URL = `${API_PROTOCOL}://${API_HOST}${API_PORT}/api`

const ODB_PROTOCOL = 'https'
const ODB_HST = 'db-backend.harmofemb.com.ua'
const ODB_URL = `${ODB_PROTOCOL}://${ODB_HST}`

const FILE_ODB_PROTOCOL = 'harmofemb-odb'
const FILE_ODB_URL = `${FILE_ODB_PROTOCOL}://`

module.exports = {
	appName: 'HARMOFEMB PUBLIC ODB',

	devMode: DEV,

	baseUrl: BASE_URL,
	apiUrl: API_URL,
	odbUrl: ODB_URL,
	fileOdb: FILE_ODB_URL,
}
