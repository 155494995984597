<template>
    <ul class="px-5 flex justify-between space-x-8">
        <li v-for="route in routes" :key="route.path">

            <router-link :to="route.path" class="py-5 flex justify-between space-x-4">
                <mdicon v-if="route.icon" :name="route.icon" class="text-amber-500"/>
                <span>{{ route.name }}</span>
                <div v-if="route.badge > 0" class="w-6 h-6 ml-2 inline-block rounded-full text-center text-white bg-red-500">
                    {{ route.badge }}
                </div>
            </router-link>

        </li>

    </ul>
</template>

<script>

export default {

    name: 'NavMenuComponent',

    computed: {
        routes() {
            return this.$router.options.routes.filter( route => route.name != undefined && route.meta.mainMenuLink == true )
        }
    }
}
</script>
