<template>
    <div class="relative">
        <div v-show="this.sending" class="absolute top-0 right-0 bottom-0 left-0 bg-white/75 backdrop-blur-sm z-10">
            <v-loader class="my-56" :loading="this.sending"/>
        </div>

        <form enctype="multipart/form-data">
            <div class="flex justify-between space-x-8">
                <div class="w-1/3">
                    <v-input-image
                        v-model="data.image"
                        id="ProductCreateImage"
                        label="Зображення"
                        name="image"
                        :formMode="formMode"
                        orientation="vertical"
                        :error="errors.image"
                    />
                </div>

                <div class="w-1/3">
                    <v-loader v-show="isModsFetching"/>
                    <ProductModsPicker
                        v-show="!isModsFetching"
                        v-model="data.mods"
                        id="ProductCreateMods"
                        :mods="mods"
                        :selected="data.mods_"
                        :error="errors.mods"
                    />

                </div>

                <div class="w-1/3">
                    <div class="flex justify-between space-x-4">
                        <div>
                            <v-loader v-show="isTypesFetching"/>
                            <v-select
                                v-show="!isTypesFetching"
                                v-model.number="data.product_type_id"
                                id="ProductCreateType"
                                label="Тип"
                                :options="types"
                                :selected="data.product_type_id"
                                :error="errors.type"
                            />
                        </div>

                        <v-input
                            v-model="data.name"
                            id="ProductCreateTitle"
                            label="Назва"
                            name="name"
                            type="text"
                            :error="errors.name"
                        />

                        <div>
                            <v-input-number
                                v-model="data.version"
                                id="ProductCreateVersion"
                                class="w-48"
                                label="Версія"
                                name="version"
                                min="1"
                                :error="errors.version"
                            />
                        </div>
                    </div>

                    <v-loader v-show="isCategoriesFetching"/>
                    <ProductCategoryPicker
                        v-show="!isCategoriesFetching"
                        v-model="data.category_id"
                        :categories="categories"
                        :error="errors.category"
                    />

                    <v-loader v-show="isPaletteFetching"/>
                    <ProductPalettePicker
                        v-show="!isPaletteFetching"
                        v-model="data.palette_id"
                        id="ProductCreatePalette"
                        :palette="palette"
                        :error="errors.palette"
                    />

                    <div class="mt-4 grid grid-cols-4 gap-4">
                        <v-input-file
                            v-model="data.label"
                            id="ProductCreateLabelFile"
                            label="Титулка"
                            name="label"
                            icon="image-filter-hdr"
                            iconSize="48"
                            accept=".jpg"
                        />

                        <v-input-file
                            v-model="data.xsd"
                            id="ProductCreateXSDFile"
                            label="XSD"
                            name="xsd"
                            icon="file-document-plus-outline"
                            iconSize="48"
                            accept=".xsd"
                            :error="errors.xsd"
                        />

                        <v-input-file
                            v-model="data.psd"
                            id="ProductCreatePSDFile"
                            label="PSD"
                            name="psd"
                            icon="file-document-plus-outline"
                            iconSize="48"
                            accept=".psd"
                            :error="errors.psd"
                        />

                        <v-input-file
                            v-model="data.rtf"
                            id="ProductCreateRTFFile"
                            label="RTF"
                            name="rtf"
                            icon="file-document-plus-outline"
                            iconSize="48"
                            accept=".rtf"
                            validation="\page"
                            :error="errors.rtf"
                        />
                    </div>

                    <v-text
                        label="Опис"
                        v-model="data.desc"
                        id="ProductCreateDesc"
                        class="mt-4"
                    />
                </div>

            </div>

            <div class="modal-footer text-right">
                <button class="mt-5 p-4 rounded-lg modal-close"
                        type="button"
                        @click="reset()"
                >Скасувати
                </button>

                <button v-if="formMode === 'create'" class="bg-amber-300 mt-5 p-4 rounded-lg"
                        type="button"
                        @click="addProduct(data)"
                >Створити
                </button>

                <button v-if="formMode === 'edit'" class="bg-amber-300 mt-5 p-4 rounded-lg"
                        type="button"
                        @click="updateProduct(uuid, data)"
                >Зберегти
                </button>
            </div>

        </form>
    </div>
</template>

<script>
import {mapActions, mapState} from "pinia";

import ProductCategoryPicker from '@/components/Product/forms/ProductCategoryPicker.vue'
import ProductPalettePicker from '@/components/Product/forms/ProductPalettePicker.vue'
import ProductModsPicker from '@/components/Product/forms/ProductModsPicker.vue'

import {useProductStore} from "@/stores/ProductStore";

export default {

    name: 'ProductCreateForm',

    components: {
        ProductCategoryPicker,
        ProductPalettePicker,
        ProductModsPicker
    },

    props: {
        formMode: {type: String, default: 'create'},
        pattern: {type: Object, default: () => ({})},
    },

    data() {
        return {
            uuid: this.$route.params.product_uuid,

            data: {
                name: '',
                version: 1,
                pattern_id: -1,
                product_type_id: -1,
                category_id: -1,
                palette_id: -1,
                mods_: [22, 28],
                mods: [22, 28],
                desc: '-',
                image: '',
                label: '',
                xsd: '',
                psd: '',
                rtf: '',
            },
        }
    },

    computed: {
        ...mapState(useProductStore, {
            types: 'getTypes',
            isTypesFetching: 'isTypesFetching',

            categories: 'getCategories',
            isCategoriesFetching: 'isCategoriesFetching',

            palette: 'getPalette',
            isPaletteFetching: 'isPaletteFetching',

            mods: 'getMods',
            isModsFetching: 'isModsFetching',

            product: 'getProduct',

            errors: 'getErrors',

            sending: 'isSendingData',
        }),
    },

    methods: {
        ...mapActions(useProductStore, [
            'fetchOptions',
            'addProduct',
            'updateProduct',
        ]),

        reset() {
            this.data.name = ''
            this.data.version = 1
            this.data.pattern_id = -1
            this.data.product_type_id = -1
            this.data.category_id = -1
            this.data.palette_id = -1
            this.data.mods = [22, 28]
            this.data.desc = '-'
        },

    },

    watch: {
        product(newValue) {
            this.data.name = newValue.name
            this.data.version = newValue.version
            this.data.pattern_id = newValue.pattern_id
            this.data.product_type_id = newValue.product_type_id
            this.data.category_id = newValue.category_id
            this.data.palette_id = newValue.palette_id
            this.data.mods_ = (this.formMode === 'edit')
                ? [...newValue.mods]
                : [22, 28]
            this.data.desc = newValue.desc
        },

        pattern(newValue) {
            this.data.pattern_id = newValue.id
            this.data.name = newValue.name
        }
    },

    async created() {
        await this.fetchOptions()
    }
}

</script>
