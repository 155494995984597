import { defineStore } from 'pinia'

import PatternService from '@/services/PatternService'

import { createPatternRequest, updatePatternRequest } from '@/requests/PatternRequests'

export const usePatternStore = defineStore( 'pattern', {

    state: () => ({
        patterns: [],
        authors: [],
        tags: [],

        pattern: {},

        loading: {
            patterns: false,
            authors: false,
            tags: false,

            pattern: false,
            products: false,

            sending: false,
        },

        modal: {
            create: false,
            edit: false,
        },

        errors: {},
    }),

    getters: {
        getPatterns( state ) {
            return state.patterns;
        },

        isPatternsFetching( state ) {
            return state.loading.patterns
        },


        getPattern( state ) {
            return state.pattern;
        },

        isPatternFetching( state ) {
            return state.loading.pattern
        },


        getTags( state ) {
            return state.tags;
        },

        isTagsFetching( state ) {
            return state.loading.tags
        },


        getAuthors( state ) {
            return state.authors;
        },

        isAuthorsFetching( state ) {
            return state.loading.authors
        },


        getErrors( state ) {
            return state.errors
        },


        isSendingData( state ) {
            return state.loading.sending
        },


        isModalCreateOpened( state ) {
            return state.modal.create
        },

        isModalEditOpened( state ) {
            return state.modal.edit
        },
    },

    actions: {
        async fetchPatterns( filter ) {
            this.loading.patterns = true

            const res = await PatternService.fetchPatterns( filter )

            try {
                this.patterns = res.data.data
            } catch ( e ) {
                this.patterns = []
            }

            this.loading.patterns = false
        },

        async fetchPattern( uuid ) {
            this.loading.pattern = true

            const res = await PatternService.fetchPattern( uuid )

            try {
                this.pattern = res.data.data
            } catch ( e ) {
                this.pattern = {}
            }

            this.loading.pattern = false
        },

        async fetchTags() {
            this.loading.tags = true

            const res = await PatternService.fetchTags()

            try {
                this.tags = res.data.data
            } catch ( e ) {
                this.tags = []
            }

            this.loading.tags = false
        },

        async fetchAuthors() {
            this.loading.authors = true

            const res = await PatternService.fetchAuthors()

            try {
                this.authors = res.data.data
            } catch ( e ) {
                this.authors = []
            }

            this.loading.authors = false
        },

        async fetchOptions() {
            this.loading.authors = true
            this.loading.tags = true

            const res = await PatternService.fetchOptions()

            try {
                this.authors = res.data.data.authors
                this.tags = res.data.data.tags
            } catch ( e ) {
                this.authors = []
                this.tags = []
            }

            this.loading.authors = false
            this.loading.tags = false
        },

        async addPattern( pattern ) {
            this.loading.sending = true

            this.errors = createPatternRequest( pattern )

            if ( Object.keys( this.errors ).length > 0 ) {
                this.loading.sending = false
                return 0;
            }

            const res = await PatternService.createPattern( pattern )

            try {
                if ( res.data.status ) {
                    this.hidePatternModal( 'create' )
                    await this.fetchPatterns()
                }
            } catch ( e ) {
                //
            }

            this.loading.sending = false

        },

        async updatePattern( uuid, pattern ) {
            this.loading.sending = true

            this.errors = updatePatternRequest( pattern )

            if ( Object.keys( this.errors ).length > 0 ) {
                this.loading.sending = false
                return 0;
            }

            const res = await PatternService.updatePattern( uuid, pattern )

            try {
                if ( res.data.status ) {
                    this.hidePatternModal( 'edit' )
                    await this.fetchPattern( uuid )
                }
            } catch ( e ) {
                //
            }

            this.loading.sending = false

        },

        showPatternModal( name ) {
            this.modal[ name ] = true
        },

        hidePatternModal( name ) {
            this.modal[ name ] = false
        },

        togglePatternModal( name ) {
            this.modal[ name ] = !this.modal[ name ]
        },
    },

} )
