<template>
    <div class="relative">
        <div v-show="sending" class="absolute top-0 right-0 bottom-0 left-0 bg-white/75 backdrop-blur-sm z-10">
            <v-loader class="my-40" :loading="sending"/>
        </div>

        <form enctype="multipart/form-data">
            <div class="flex justify-between space-x-8">

                <div class="w-1/3">
                    <v-input-image
                        v-model="data.image"
                        id="PatternCreateImage"
                        label="Зображення"
                        :formMode="formMode"
                        :error="errors.image"
                    />
                </div>

                <div class="w-1/3">
                    <v-loader v-show="isTagsFetching"/>
                    <PatternTagsPicker
                        v-show="!isTagsFetching"
                        v-model="data.tags"
                        id="PatternCreateTags"
                        :error="errors.tags"
                    />
                </div>

                <div class="w-1/3">
                    <v-input
                        v-model="data.name"
                        id="PatternCreateTitle"
                        label="Назва"
                        type="text"
                        :error="errors.name"
                    />

                    <v-loader v-show="isAuthorsFetching"/>
                    <PatternAuthorsPicker
                        v-show="!isAuthorsFetching"
                        v-model.number="data.author_id"
                        id="PatternCreateAuthor"
                        :error="errors.author"
                    />

                    <v-text
                        label="Опис"
                        v-model="data.desc"
                        id="PatternCreateDesc"
                    />
                </div>

            </div>

            <div class="modal-footer text-right">
                <button @click="reset()"
                        class="mt-5 p-4 rounded-lg modal-close"
                        type="button">Скасувати
                </button>

                <button @click="addPattern(data)"
                        v-if="formMode === 'create'"
                        class="bg-amber-300 mt-5 p-4 rounded-lg"
                        type="button">Створити
                </button>

                <button @click="updatePattern(uuid, data)"
                        v-if="formMode === 'edit'"
                        class="bg-amber-300 mt-5 p-4 rounded-lg"
                        type="button">Зберегти
                </button>
            </div>

        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import PatternAuthorsPicker from '@/components/Pattern/forms/PatternAuthorsPicker'
import PatternTagsPicker from '@/components/Pattern/forms/PatternTagsPicker'

import { usePatternStore } from "@/stores/PatternStore";

export default {

    name: 'PatternCreateForm',

    components: {
        PatternAuthorsPicker,
        PatternTagsPicker,
    },

    props: {
        formMode: { type: String, default: 'create' },
    },

    data() {
        return {
            uuid: this.$route.params.pattern_uuid,
            data: {
                name: '',
                image: '',
                desc: '-',
                author_id: -1,
                tags: [],
            },

        }
    },

    computed: {
        ...mapState( usePatternStore, {
            pattern: 'getPattern',
            authors: 'getAuthors',
            tags: 'getTags',
            products: 'getProducts',
            isAuthorsFetching: 'isAuthorsFetching',
            isTagsFetching: 'isTagsFetching',
            errors: 'getErrors',
            sending: 'isSendingData',
        } ),
    },

    methods: {
        ...mapActions( usePatternStore, [
            'addPattern',
            'updatePattern',
        ] ),

        reset() {
            this.data.name = this.pattern.name
            this.data.desc = this.pattern.desc
            this.data.author_id = this.pattern.author_id
            this.data.tags = (this.formMode === 'edit')
                ? this.pattern.tags.map(t => t.id)
                : []
        },
    },

    watch: {
        pattern(newPattern) {
            this.data.name = newPattern.name
            this.data.desc = newPattern.desc
            this.data.author_id = newPattern.author_id
            this.data.tags = (this.formMode === 'edit')
                ? newPattern.tags.map(t => t.id)
                : []
        }
    },
}

</script>
