import { defineStore } from 'pinia'

import ProductService from '@/services/ProductService'

import { createProductRequest, updateProductRequest } from '@/requests/ProductRequests'
import PatternService from "@/services/PatternService";

export const useProductStore = defineStore( 'product', {

    state: () => ({
        pattern_uuid: '',

        types: [],
        categories: [],
        palette: [],
        mods: [],

        products: [],
        product: {},

        loading: {
            types: false,
            categories: false,
            palette: false,
            mods: false,

            product: false,

            sending: false,
        },

        modal: {
            create: false,
            edit: false,
        },

        errors: {},

    }),

    getters: {
        getTypes( state ) {
            return state.types;
        },

        isTypesFetching( state ) {
            return state.loading.types
        },


        getCategories( state ) {
            return state.categories;
        },

        isCategoriesFetching( state ) {
            return state.loading.categories
        },


        getPalette( state ) {
            return state.palette;
        },

        isPaletteFetching( state ) {
            return state.loading.palette
        },


        getMods( state ) {
            return state.mods;
        },

        isModsFetching( state ) {
            return state.loading.mods
        },


        getProducts( state ) {
            return state.products;
        },

        isProductsFetching( state ) {
            return state.loading.products
        },


        getProduct( state ) {
            return state.product;
        },

        isProductFetching( state ) {
            return state.loading.product
        },


        getErrors( state ) {
            return state.errors
        },


        isSendingData( state ) {
            return state.loading.sending
        },


        isModalCreateOpened( state ) {
            return state.modal.create
        },

        isModalEditOpened( state ) {
            return state.modal.edit
        },
    },

    actions: {
        async fetchTypes() {
            this.loading.types = true

            const res = await ProductService.fetchTypes()

            try {
                this.types = res.data.data
            } catch ( e ) {
                this.types = []
            }

            this.loading.types = false
        },

        async fetchCategories( filter ) {
            this.loading.categories = true

            const res = await ProductService.fetchProductCategoriesTree( filter )

            try {
                this.categories = res.data.data
            } catch ( e ) {
                this.categories = []
            }

            this.loading.categories = false
        },

        async fetchPalette() {
            this.loading.palette = true

            const res = await ProductService.fetchPalette()

            try {
                this.palette = res.data.data
            } catch ( e ) {
                this.palette = []
            }

            this.loading.palette = false
        },

        async fetchMods() {
            this.loading.mods = true

            const res = await ProductService.fetchTags()

            try {
                this.mods = res.data.data
            } catch ( e ) {
                this.mods = []
            }

            this.loading.mods = false
        },

        async fetchProducts( uuid ) {
            uuid = uuid || this.pattern_uuid

            this.pattern_uuid = uuid
            this.loading.products = true
            this.products = []

            const res = await PatternService.fetchPatternProductsByPalette( uuid )

            try {
                this.products = res.data?.data.filter( item => item.products.length > 0 )
            } catch ( e ) {
                this.products = []
            }

            this.loading.products = false
        },

        async fetchProduct( uuid ) {
            this.loading.product = true

            const res = await ProductService.fetchProduct( uuid )

            try {
                this.product = res.data.data
            } catch ( e ) {
                this.product = {}
            }

            this.loading.product = false
        },

        async fetchOptions() {
            this.loading.types = true
            this.loading.categories = true
            this.loading.palette = true
            this.loading.mods = true

            const res = await ProductService.fetchOptions()

            try {
                this.types = res.data.data.types
                this.categories = res.data.data.categories
                this.palette = res.data.data.palette
                this.mods = res.data.data.mods
            } catch ( e ) {
                this.types = []
                this.categories = []
                this.palette = []
                this.mods = []
            }

            this.loading.types = false
            this.loading.categories = false
            this.loading.palette = false
            this.loading.mods = false
        },

        async addProduct( product ) {
            this.loading.sending = true

            this.errors = createProductRequest( product )

            if ( Object.keys( this.errors ).length > 0 ) {
                this.loading.sending = false
                return 0;
            }

            const res = await ProductService.createProduct( product )

            try {
                if ( res.data.status ) {
                    this.hideProductModal('create')
                    await this.fetchProducts()
                }
            } catch ( e ) {
                //
            }

            this.loading.sending = false
        },

        async updateProduct( uuid, product ) {
            this.loading.sending = true

            this.errors = updateProductRequest( product )

            if ( Object.keys( this.errors ).length > 0 ) {
                this.loading.sending = false
                return 0;
            }

            const res = await ProductService.updateProduct( uuid, product )

            try {
                if ( res.data.status ) {
                    this.hideProductModal('edit')
                    await this.fetchProduct(uuid)
                }
            } catch ( e ) {
                //
            }

            this.loading.sending = false
        },

        showProductModal( name ) {
            this.modal[name] = true
        },

        hideProductModal( name ) {
            this.modal[name] = false
        },

        toggleProductModal( name ) {
            this.modal[name] = !this.modal[name]
        },


    },

} )
