<template>
    <div class="border-l">
        <div v-show="auth" class="group relative">

            <div @click="isOpen = !isOpen" class="dropdown flex justify-between space-x-2 cursor-pointer">
                <img class="w-10 h-10 m-3 rounded-full" src="@/assets/img/avatar-male.png" />

                <div class="py-5 flex justify-between space-x-3">
                    <span>{{ user?.name }}</span>

                    <div v-show="user?.badge > 0" class="w-6 h-6 inline-block rounded-full text-center text-white bg-red-500">
                        {{ user?.badge }}
                    </div>

                    <mdicon name="chevron-down"/>
                </div>
            </div>

            <transition name="fade" appear>
                <ul v-show="isOpen" class="dropdown-menu absolute top-full right-0 w-full rounded-md shadow-lg bg-white overflow-hidden z-10">

                    <li v-for="item in items" :key="item.text" class="py-2 hover:bg-gray-50 ">
                        <a @click="item.action" class="px-3 leading-none flex justify-left space-x-3 cursor-pointer">
                            <mdicon :name="item.icon"/>
                            <span>{{ item.text }}</span>
                        </a>
                    </li>

                </ul>
            </transition>

        </div>

    </div>
</template>

<script>
import { useAuthStore } from '@/stores'
import { mapState } from "pinia";

export default {
    name: 'NavUserComponent',

    data() {
        return {
            isOpen: false,

            items: [
                { icon: 'account-outline', text: 'Profile' },
                { icon: 'logout', text: 'Logout', action: this.logout }
            ]
        }
    },

    computed: {
        ...mapState(useAuthStore, {
            auth: 'isUserAuth',
            user: 'getUser',
        })
    },

    methods: {
        async logout() {
            const authStore = useAuthStore()
            await authStore.logout()
        },
    },

    mounted() {
        if(!this.auth) this.$router.push('/auth')
    }
}
</script>
