import ApiRequestProvider from '@/providers/ApiRequestProvider'

export default {

	fetchProducts( filter ) {
		return ApiRequestProvider._post( '/product/getProducts', filter )
	},

	fetchProduct( uuid ) {
		return ApiRequestProvider._get( `/product/${uuid}` )
	},

	fetchTypes () {
		return ApiRequestProvider._post( '/product/types/getProductTypes' )
	},

	fetchProductCategories( filter ) {
		return ApiRequestProvider._post( '/product/categories/getCategories', filter )
	},

	fetchProductCategoriesTree( filter ) {
		return ApiRequestProvider._post( '/product/categories/getCategoriesTree', filter )
	},

	fetchPalette () {
		return ApiRequestProvider._post( '/product/palette/getPalette' )
	},

	fetchMods () {
		return ApiRequestProvider._post( '/product/types/getProductTypes' )
	},

	fetchOptions() {
		return ApiRequestProvider._get( '/product/getOptions' )
	},

	createProduct( data ) {
		const formData = new FormData()

		formData.append( 'name', data.name )
		formData.append( 'pattern_id', data.pattern_id )
		formData.append( 'product_type_id', data.product_type_id )
		formData.append( 'category_id', data.category_id )
		formData.append( 'palette_id', data.palette_id )
		formData.append( 'version', data.version )
		formData.append( 'mods', Object.values(data.mods) )
		formData.append( 'desc', data.desc )

		formData.append( 'image', data.image )
		formData.append( 'label', data.label )
		formData.append( 'xsd', data.xsd )
		formData.append( 'psd', data.psd )
		formData.append( 'rtf', data.rtf )

		const headers = {
			'Content-type': 'multipart/form-data'
		}

		return ApiRequestProvider._post( '/product/addProduct', formData, headers )
	},

	updateProduct( uuid, data ) {
		const formData = new FormData()

		formData.append( '_method', 'PUT' )
		formData.append( 'name', data.name )
		formData.append( 'pattern_id', data.pattern_id )
		formData.append( 'product_type_id', data.product_type_id )
		formData.append( 'category_id', data.category_id )
		formData.append( 'palette_id', data.palette_id )
		formData.append( 'version', data.version )
		formData.append( 'mods', Object.values(data.mods) )
		formData.append( 'desc', data.desc )

		formData.append( 'image', data.image )
		formData.append( 'label', data.label )
		formData.append( 'xsd', data.xsd )
		formData.append( 'psd', data.psd )
		formData.append( 'rtf', data.rtf )

		const headers = {
			'Content-type': 'multipart/form-data'
		}

		return ApiRequestProvider._post( `/product/${uuid}/update`, formData, headers )
	},

}
