import ApiRequestProvider from '@/providers/ApiRequestProvider'

export default {

    fetchPatterns( filter = {} ) {
        return ApiRequestProvider._get( '/pattern', filter )
    },

    fetchPattern( uuid ) {
        return ApiRequestProvider._get( `/pattern/${uuid}` )
    },

    fetchPatternProductsByPalette( uuid ) {
        return ApiRequestProvider._get( `/pattern/${uuid}/productsByPalette` )
    },

    fetchTags() {
        return ApiRequestProvider._get( '/pattern/tags/getTags' )
    },

    fetchAuthors() {
        return ApiRequestProvider._get( '/pattern/authors/getAuthors' )
    },

    fetchOptions() {
        return ApiRequestProvider._get( '/pattern/getOptions' )
    },

    createPattern( data ) {
        const formData = new FormData()

        formData.append( 'name', data.name )
        formData.append( 'author_id', data.author_id )
        formData.append( 'image', data.image )
        formData.append( 'tags', data.tags )
        formData.append( 'desc', data.desc )

        const headers = {
            'Content-type': 'multipart/form-data'
        }

        return ApiRequestProvider._post( '/pattern/addPattern', formData, headers )
    },

    updatePattern( uuid, data ) {
        const formData = new FormData()

        formData.append( '_method', 'PUT' )
        formData.append( 'name', data.name )
        formData.append( 'author_id', data.author_id )
        formData.append( 'image', data.image )
        formData.append( 'tags', data.tags )
        formData.append( 'desc', data.desc )

        const headers = {
            'Content-type': 'multipart/form-data'
        }

        return ApiRequestProvider._post( `/pattern/${uuid}/update`, formData, headers )
    },

}
