<template>
    <div class="w-full">
        <div>Теги</div>

        <div class="pattern-tags flex flex-wrap">

            <ul>
                <li v-for="tagCategory in tags" :key="tagCategory.id">
                    <h4>{{ tagCategory.name }}</h4>
                    <ul class="w-full flex flex-wrap">
                        <li v-for="tag in tagCategory.tags" :key="tag.id">
                            <input type="checkbox"
                                   v-model.number="selected"
                                   :id="`PatternCreateTag${tag.id}`"
                                   :value="tag.id"
                                   :checked="selected.includes(tag.id)"
                                   class="hidden peer"
                            >
                            <label
                                :for="`PatternCreateTag${tag.id}`"
                                class="block p-1 px-2 mr-1 mb-1 text-center text-sm border-2 border-white rounded-full cursor-pointer select-none peer-checked:border-black"
                                :class="[`bg-${tagCategory.color}`, `text-${tagCategory.text_color}`]"
                            >
                                {{ tag.name }}
                            </label>
                        </li>
                    </ul>
                </li>
            </ul>

        </div>

        <div v-show="error.length > 0" class="text-red-500">{{ error }}</div>

    </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { usePatternStore } from "@/stores/PatternStore";

export default {

    name: 'PatternTagsPicker',

    emits: [ 'update:modelValue' ],

    props: {
        modelValue: { type: Array, default: () => [] },
        error: { type: String, default: '' },
    },

    data() {
        return {
            selected: [],
        }
    },

    computed: {
        ...mapState( usePatternStore, {
            tags: 'getTags',
            isTagsFetching: 'isTagsFetching',
        } ),
    },

    methods: {
        ...mapActions( usePatternStore, [
            'fetchTags',
        ] ),
    },

    watch: {
        modelValue(newData) {
            this.selected = newData
        }
    },

    mounted() {
        this.fetchTags()
    },

    updated() {
        this.$emit( 'update:modelValue', this.selected )
    },

}

</script>
