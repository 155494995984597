<template>
    <div class="w-full mb-3">
        <div>Категорії</div>

        <div class="product-categories -mb-3 grid grid-cols-3 gap-x-4">
            <v-select
                v-model.number="sel[0]"
                name="category_id"
                :options="categoriesBuffer[0]"
                :selected="sel[0]"
                @change="setL1Category"
            />

            <v-select
                v-show="categoriesFilteredL2.length"
                v-model.number="sel[1]"
                name="category_id"
                :options="categoriesFilteredL2"
                :selected="sel[1]"
                @change="setL2Category"
            />

            <v-select
                v-show="categoriesFilteredL3.length"
                v-model.number="sel[2]"
                name="category_id"
                :options="categoriesFilteredL3"
                :selected="sel[2]"
                @change="setL3Category"
            />

        </div>

        <div v-show="error.length > 0" class="text-red-500">{{ error }}</div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useProductStore } from "@/stores/ProductStore";

export default {

    name: 'ProductCategoryPicker',

    emits: [ 'update:modelValue' ],

    props: {
        modelValue: { type: [ String, Number ], default: -1 },
        categories: { type: Object, default: () => ({}) },
        error: { type: String, default: '' },
    },

    data() {
        return {
            categoriesBuffer: [
                [],
                [],
                [],
            ],

            categoriesFilteredL2: [],
            categoriesFilteredL3: [],

            sel: [ 0, 0, 0 ],

            selected: -1,
        }
    },

    computed: {
        ...mapState( useProductStore, {
            categories: 'getCategories',
            isCategoriesFetching: 'isCategoriesFetching',
        } )
    },

    methods: {
        setL1Category() {
            this.sel[ 1 ] = 0
            this.categoriesFilteredL2 = []

            this.sel[ 2 ] = 0
            this.categoriesFilteredL3 = []

            this.categoriesFilteredL2 = this.categoriesBuffer[ 1 ].filter( c => c.parent === this.sel[ 0 ] )
        },
        setL2Category() {
            this.sel[ 2 ] = 0
            this.categoriesFilteredL3 = this.categoriesBuffer[ 2 ].filter( c => c.parent === this.sel[ 1 ] )
        },
    },

    watch: {
        modelValue( newValue ) {
            if (newValue > 0) {
                this.selected = newValue
            }
        },
        categories( newValue ) {
            newValue.forEach( c => {
                this.categoriesBuffer[ 0 ].push( { id: c.id, name: c.name } )

                c.children.forEach( cc => {
                    this.categoriesBuffer[ 1 ].push( { id: cc.id, name: cc.name, parent: cc.parent_id } )

                    cc.children.forEach( ccc => {
                        this.categoriesBuffer[ 2 ].push( { id: ccc.id, name: ccc.name, parent: ccc.parent_id } )
                    } )

                } )
            } )

            this.categoriesBuffer.forEach((c, i) => {
                c.forEach(cc => {
                    if(cc.id === this.selected) {

                        if(i === 1) {
                            this.sel[0] = cc.parent
                            this.setL1Category()

                            this.sel[1] = this.selected
                            this.setL2Category()
                        }

                        if(i === 2) {
                            const c0 = this.categoriesBuffer[1].filter( _c0 => _c0.id === cc.parent)
                            this.sel[0] = c0[0].parent
                            this.setL1Category()

                            this.sel[1] = cc.parent
                            this.setL2Category()

                            this.sel[2] = this.selected
                        }
                    }
                })
            })
        }
    },

    updated() {
        this.$emit( 'update:modelValue', (this.sel[ 2 ] > 0) ? this.sel[ 2 ] : this.sel[ 1 ] )
    },


}

</script>
