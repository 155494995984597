<template>
    <div class="w-full mb-3">
        <label :for="id" class="w-full flex justify-left space-x-2">
            Автори
        </label>

        <select
            class="w-full h-12 p-2 border border-gray-300 rounded-lg bg-white focus:outline-none focus:ring-2 focus:ring-gray-300 disabled:bg-gray-100 disabled:border-gray-100 disabled:text-gray-400"
            :id="id"
            :title="label"
            :required="required"
            :disabled="disabled"
            @change="$emit('update:modelValue', $event.target.value)"
        >
            <option value="-1" selected>- вибрати -</option>
            <option v-for="author in authors" :key="author.id"
                    :value="author.id"
                    :selected="author.id === modelValue"
            >{{ author.name }}
            </option>
        </select>

        <div v-show="error.length > 0" class="text-red-500">{{ error }}</div>
    </div>
</template>

<script>
import { mapActions, mapState } from "pinia";

import { usePatternStore } from "@/stores/PatternStore";

export default {

    name: 'PatternAuthorsPicker',

    emits: [ 'update:modelValue' ],

    props: {
        id: String,
        modelValue: { type: [ Number, String ], default: -1 },
        required: { type: Boolean, default: true },
        disabled: { type: Boolean, default: false },
        error: { type: String, default: '' }
    },

    computed: {
        ...mapState( usePatternStore, {
            authors: 'getAuthors',
            isAuthorsFetching: 'isAuthorsFetching',
        } ),
    },

    methods: {
        ...mapActions( usePatternStore, [
            'fetchAuthors',
        ] ),
    },

    created() {
        this.fetchAuthors()
    }


}

</script>
