<template>
    <div class="group relative">

        <div @click="show = !show" class="dropdown flex justify-between space-x-2 cursor-pointer">
            <div class="py-5 flex justify-between space-x-2">
                <mdicon v-if="icon" :name="icon"/>
                <span v-if="text">{{ text }}</span>
                <v-badge :count="badge" inline="true"/>
                <mdicon name="chevron-down"/>
            </div>
        </div>

        <transition name="fade" appear>
            <ul v-if="show" class="dropdown-menu absolute top-full right-0 w-full rounded-md shadow-lg bg-white overflow-hidden z-10">
                <slot></slot>
                <!-- <li v-for="item in items" :key="item.text" class="py-2 hover:bg-gray-50 ">
                    <a @click="item.action" class="px-3 leading-none flex justify-left space-x-3 cursor-pointer">
                        <mdicon :name="item.icon"/>
                        <span>{{ item.text }}</span>
                    </a>
                </li> -->
            </ul>
        </transition>

    </div>

    <div class="w-min-12 p-2.5 border rounded-md flex justify-center space-x-2 cursor-pointer">

    </div>
</template>

<script>

export default {

    name: 'v-dropdown',

    props: {
        icon: {
            type: String,
            default: null
        },
        text: {
            type: String,
            default: null
        },
        badge: {
            type: Number,
            default: 1
        }
    },

    data() {
        return {
            show: false
        }
    }

}

</script>
