<template>
    <header v-if="$route.meta.displayHeader">
        <NavbarComponent title="HARMOFEMB DB" icon="database-outline">
            <template #search><NavSearchComponent class="mr-auto border-r"/></template>
            <template #menu><NavMenuComponent/></template>
            <template #volume><NavVolumeComponent class="border-l"/></template>
            <template #notification><NavNotificationComponent class="border-l"/></template>
            <template #user><NavUserComponent class="border-l"/></template>
<!--            <template #loader><v-page-loader/></template>-->
        </NavbarComponent>
    </header>
    <main>
        <router-view/>
    </main>
</template>

<script>
    import NavbarComponent from '@/components/_ui/nav/NavbarComponent'

    import NavVolumeComponent from '@/components/_ui/nav/NavVolumeComponent'
    import NavMenuComponent from '@/components/_ui/nav/NavMenuComponent'
    import NavSearchComponent from '@/components/_ui/nav/NavSearchComponent'
    import NavNotificationComponent from '@/components/_ui/nav/NavNotificationComponent'
    import NavUserComponent from '@/components/_ui/nav/NavUserComponent'

    export default {
        name: 'App',
        components: {
            NavbarComponent,

            NavVolumeComponent,
            NavSearchComponent,
            NavNotificationComponent,
            NavUserComponent,
            NavMenuComponent,

        }
    }
</script>
