<template>
    <v-page-loader :loading="isProductFetching"/>

    <v-page-head
        v-show="!isProductFetching"
        :title="`${product.type?.name} &quot;${product.name}&quot; ${product.version} ${product.palette?.name}`"
    >
        <template #buttons-r>
            <v-button icon="pencil-outline" @click="showProductModal('edit')"/>
        </template>
    </v-page-head>

    <div v-show="!isProductFetching" class="max-w-screen px-4 mx-auto md:flex space-x-12 text-gray-700">

        <div class="product-image w-1/3">
            <img class="w-full" :src="getImage()" alt="">
        </div>

        <div class="product-info w-1/3 text-lg">
            <ul class="grid grid-cols-2 gap-6">
                <li>
                    <span class="font-semibold">ID</span>
                    <br>{{ product.id }}
                </li>

                <li>
                    <span class="font-semibold">Версія</span>
                    <br>{{ product.version }}
                </li>

                <li>
                    <span class="font-semibold">Орнамент</span>
                    <br>
                    <router-link :to="`/patterns/${product.pattern?.uuid}`"
                                 class="text-amber-500 hover:text-amber-300">
                        {{ product.pattern?.name }}
                    </router-link>
                </li>

                <li>
                    <span class="font-semibold">Тип</span>
                    <br>{{ `[${product.type?.short}] ${product.type?.name}` }}
                </li>

                <li>
                    <span class="font-semibold">Відтінок</span>
                    <div class="flex space-x-4">
                        <div
                            class="w-6 h-6 rounded-full shadow-xl"
                            :class="getColor(product.palette?.colors)"
                        ></div>
                        <span>{{ product.palette?.name }}</span>
                    </div>
                </li>

                <li>
                    <span class="font-semibold">Категорія</span>
                    <br><span>{{ `${product.category?.parent?.parent?.name} ⟶ ${product.category?.parent?.name} ⟶ ${product.category?.name}` }}
                </span>
                </li>

                <li>
                    <span class="font-semibold">Створено</span>
                    <br><span v-html="getDateWithRelative( product.created_at )"></span>
                </li>

                <li>
                    <span class="font-semibold">Змінено</span>
                    <br><span v-html="getDateWithRelative( product.updated_at )"></span>
                </li>
            </ul>

            <hr class="my-6 border-t">

            <span class="font-semibold">Модифікації</span>
            <ul class="grid grid-cols-2 gap-4 text-sm">
                <li v-for="mod in product.mods" :key="mod.id">
                    <div class="w-full mb-3">
                        {{ mod.category.name }}
                        <span v-if="mod.category.desc" class="text-gray-400">{{ mod.category.desc }}</span>
                    </div>
                    <div class="w-full mb-3">
						<span
                            class="p-1 text-center text-text-gray-700 font-semibold bg-amber-400 border border-gray-200 rounded-lg cursor-pointer">
							{{ mod.name }}
						</span>
                    </div>
                </li>
            </ul>

            <hr class="my-6 border-t">

            <span class="font-semibold">Теги</span>
            <br><span class="text-sm">Наслідувано від орнаменту</span>

            <hr class="my-6 border-t">

            <span class="font-semibold">Опис</span>
            <br><span>{{ product.desc }}</span>
        </div>

    </div>

    <v-modal
        v-show="isProductEditModalOpened"
        @hide="hideProductModal('edit')"
        w="w-full xl:w-4/5"
        title="Редагування товару"
    >
        <ProductCreateForm formMode="edit"/>
    </v-modal>

</template>

<script>
import {mapActions, mapState} from "pinia";
import { devMode, odbUrl } from "@/config";
import helper from '@/helper'

import ProductCreateForm from '@/components/Product/forms/ProductCreateForm'

import {useProductStore} from "@/stores/ProductStore";

export default {

    name: 'ProductPageView',

    components: {
        ProductCreateForm,
    },

    data() {
        return {
            uuid: this.$route.params.product_uuid,
        }
    },

    computed: {
        ...mapState(useProductStore, {
            product: 'getProduct',
            isProductFetching: 'isProductFetching',

            isProductEditModalOpened: 'isModalEditOpened',
        }),

    },

    methods: {
        ...mapActions(useProductStore, [
            'fetchProduct',
            'showProductModal',
            'hideProductModal',
        ]),

        getImage() {
            return (devMode)
                ? 'https://via.placeholder.com/1200x1800'
                : `${odbUrl}/storage/catalog/products/${this.product.uuid}/main.jpg`
        },
        getDate( date ) {
            return this.$moment( date ).format( 'DD.MM.YYYY HH:mm' )
        },
        getRelativeDate( date ) {
            return this.$moment( date ).fromNow()
        },
        getDateWithRelative( date ) {
            return this.$moment( date ).format( 'DD.MM.YYYY HH:mm' ) + '<br>' + this.$moment( date ).fromNow()
        },
        getColor( colors ) {
            return helper.getPaletteColor( colors )
        }

    },

    async created() {
        await this.fetchProduct( this.uuid )
    },

}

</script>
