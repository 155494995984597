import PageHead from '@/components/_ui/PageHead'
import Breadcrumbs from '@/components/_ui/Breadcrumbs'
import Dropdown from '@/components/_ui/Dropdown'

import Modal from '@/components/_ui/Modal'
import PageLoader from '@/components/_ui/PageLoader'
import Loader from '@/components/_ui/Loader'

import Badge from '@/components/_ui/Badge'
import Button from '@/components/_ui/Button'

import Input from '@/components/_ui/Input'
import InputNumber from '@/components/_ui/InputNumber'
import InputRadioGroup from '@/components/_ui/InputRadioGroup'
import Text from '@/components/_ui/Text'
import InputImage from '@/components/_ui/InputImage'
import InputFile from '@/components/_ui/InputFile'
import Checkbox from '@/components/_ui/Checkbox'
// import CheckboxMulti from '@/components/_ui/CheckboxMulti'
import Select from '@/components/_ui/Select'

export default [
    PageHead,
    Breadcrumbs,
    Dropdown,

    Badge,
    Button,

    Modal,
    PageLoader,
    Loader,

    Input,
    InputNumber,
    InputRadioGroup,
    Text,
    InputImage,
    InputFile,
    Checkbox,
    // CheckboxMulti,
    Select,
]
