<template>
    <v-page-head title="Головна"/>
</template>

<script>

export default {
    name: 'HomeView',
}

</script>
