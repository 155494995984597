<template>
	<div class="w-full mb-3">
		<label v-if="label">
			{{ label }}
			<span v-if="desc" class="text-gray-400">{{ desc }}</span>
		</label>

		<ul class="w-full flex flex-wrap justify-start gap-1">
			<li v-for="item in data" :key="item.id">
				<input
					v-model="selected"
					type="radio" :id="id + item.id" :name="name" :value="item.id" class="hidden peer" required>
				<label
					class="block min-w-[3rem] text-center text-gray-400 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-amber-400 peer-checked:bg-amber-400 peer-checked:text-gray-700 font-semibold hover:text-gray-600 hover:bg-gray-100"
					:for="id + item.id" :class="[`p-${p}`]"
				>
					{{ item.name }}
				</label>
			</li>
		</ul>

		<div v-show="error.length > 0" class="text-red-500">{{ error }}</div>
	</div>
</template>

<script>

export default {

	name: 'v-input-radio-group',

	emits: ['update:selected'],

	props: {
		data: Array,
		p: {type: Number, default: 2},
		label: String,
		desc: String,
		id: String,
		name: String,
		modelValue: {type: [String, Number], default: ''},
		error: {type: String, default: ''},
	},

	data() {
		return {
			selected: '',
		}
	},

}

</script>
