import {createRouter, createWebHistory} from 'vue-router'

import HomeView from '@/views/HomeView.vue'
import AuthView from '@/views/AuthView.vue'

import PatternListView from '@/views/pattern/PatternListView.vue'
import PatternPageView from '@/views/pattern/PatternPageView.vue'

import ProductPageView from '@/views/product/ProductPageView.vue'

// import OldTreeListView from '@/views/oldtree/OldTreeListView.vue'

// import Error404View from '@/views/errors/Error404View.vue'

const routes = [
    {
        path: '/',
        component: HomeView,
        name: 'Головна',
        icon: 'home-outline',
        badge: 0,
        meta: {
            displayHeader: true,
            mainMenuLink: true,
        }
    },

    {
        path: '/auth',
        component: AuthView,
        name: 'Вхід',
        icon: 'home-outline',
        badge: 0,
        meta: {
            displayHeader: false,
            mainMenuLink: false,
        }
    },

    {
        path: '/patterns',
        component: PatternListView,
        name: 'Узори',
        icon: 'palette',
        badge: 0,
        meta: {
            displayHeader: true,
            mainMenuLink: true,
        }
    },

    {
        path: '/patterns/:pattern_uuid',
        component: PatternPageView,
        meta: {
            displayHeader: true,
            mainMenuLink: true,
        }
    },

    {
        path: '/patterns/:pattern_uuid/:product_uuid',
        component: ProductPageView,
        meta: {
            displayHeader: true,
            mainMenuLink: true,
        }
    },

    // {
    //     path: '/oldtree',
    //     component: OldTreeListView,
    //     name: 'Стара база',
    //     icon: 'file-tree',
    //     badge: 0,
    //     meta: {
    //         displayHeader: true,
    //         mainMenuLink: true,
    //     }
    // },

    // { path: '*', component: Error404View }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
