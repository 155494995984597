<template>
    <div class="w-full">
        <div>Відтінки</div>

        <div class="product-palette flex flex-wrap">

            <ul class="w-full flex flex-wrap justify-start gap-1">
                <li v-for="item in palette" :key="item.id">
                    <input type="radio"
                           v-model.number="selected"
                           :id="`ProductCreatePalette${item.id}`"
                           :value="item.id"
                           :checked="selected === item.id"
                           class="hidden peer"
                    >
                    <label :for="`ProductCreatePalette${item.id}`"
                           class="block w-8 h-8 m-0.5 rounded-full border-white cursor-pointer shadow-xl peer-checked:border-4"
                           :class="getColor(item.colors)"
                    ></label>
                </li>
            </ul>

        </div>

        <div v-show="error.length > 0" class="text-red-500">{{ error }}</div>
    </div>
</template>

<script>
import helper from "@/helper";

export default {

    name: 'ProductPalettePicker',

    emits: ['update:modelValue'],

    props: {
        modelValue: {type: [String, Number], default: ''},
        palette: Array,
        error: {type: String, default: ''},
    },

    data() {
        return {
            selected: -1,
        }
    },

    methods: {
        getColor( colors ) {
            return helper.getPaletteColor( colors )
        }
    },

    watch: {
        modelValue(newData) {
            this.selected = newData
        }
    },

    updated() {
        this.$emit( 'update:modelValue', this.selected )
    },

}

</script>
