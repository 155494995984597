<template>
    <v-page-loader :loading="isProductsFetching"/>

    <v-page-head :title="pattern.name">
        <template #buttons-r>
            <v-button icon="pencil-outline" @click="showPatternModal('edit')"/>
            <v-button icon="plus" @click="showProductModal('create')"/>
        </template>
    </v-page-head>

    <div class="max-w-screen px-4 mx-auto md:flex space-x-6 text-gray-700">

        <div class="w-full md:w-1/6 2xl:w-1/6">
            <div v-show="!isPatternFetching">
                <img class="w-full" :src="getImage()" alt="">

                <ul class="pattern-info mt-4 grid grid-cols-2">
                    <li class="py-2">
                        <span class="font-semibold">ID</span>
                        <br>{{ pattern.id }}
                    </li>
                    <li class="py-2">
                        <span class="font-semibold">Автор</span>
                        <br>{{ pattern.author?.name }}
                    </li>
                    <li class="py-2">
                        <span class="font-semibold">Створено</span>
                        <br><span v-html="getDateWithRelative( pattern.created_at )"></span>
                    </li>
                    <li class="py-2">
                        <span class="font-semibold">Змінено</span>
                        <br><span v-html="getDateWithRelative( pattern.updated_at )"></span>
                    </li>
                </ul>

                <hr class="my-6 border-t">

                <span class="font-semibold">Теги</span>
                <ul class="pattern-tags flex flex-wrap">
                    <li
                        v-for="tag in pattern.tags"
                        :key="tag.id"
                        class="block p-1 px-2 mr-1 mb-2 text-center text-sm rounded-full cursor-pointer shadow-lg"
                        :class="[`bg-${tag.category.color}`, `shadow-${tag.category.color}`, `text-${tag.category.text_color}`]"
                    >{{ tag.name }}
                    </li>
                </ul>

                <hr class="my-6 border-t">

                <span class="font-semibold">Опис</span>
                <div class="">{{ pattern.desc }}</div>
            </div>
        </div>

        <div class="w-full md:w-5/6 2xl:w-5/6">
            <v-loader v-show="isProductsFetching" class="my-20"/>
            <template v-if="!isProductsFetching">
                <div class="pattern-products w-full flex justify-start space-x-6 pl-6 border-l">
                    <div
                        class="products-set w-40 flex flex-col space-y-4 pr-6 border-r "
                        v-for="(item, i) in products" :key="i"
                    >
                        <div class="palette-label text-sm font-semibold flex justify-between space-x-4">
                            <div
                                class="w-6 h-6 rounded-full shadow-xl"
                                :class="getColor(item.colors)"
                                :title="item.name"
                            ></div>
                            <span class="text-gray-500">{{ item.products.length }}</span>
                        </div>
                        <div class="palette-products flex flex-col space-y-4">
                            <ProductItem
                                v-for="(product, i) in item.products"
                                :key="i"
                                :patternUUID="uuid"
                                :product="product"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </div>

    </div>

    <v-modal v-show="isPatternEditModalOpened"
             @hide="hidePatternModal('edit')"
             w="w-full md:w-4/5"
             title="Редагування орнаменту">
        <PatternCreateForm
            formMode="edit"
            :uuid="uuid"
        />
    </v-modal>

    <v-modal v-show="isProductCreateModalOpened"
             @hide="hideProductModal('create')"
             w="w-full xl:w-4/5"
             title="Новий товар">
        <ProductCreateForm
            :pattern="pattern"
        />
    </v-modal>

</template>

<script>
import { mapActions, mapState } from "pinia";
import { devMode, odbUrl } from "@/config";
import helper from '@/helper'

import PatternCreateForm from '@/components/Pattern/forms/PatternCreateForm'
import ProductCreateForm from '@/components/Product/forms/ProductCreateForm'
import ProductItem from '@/components/Product/ProductItem'

import { usePatternStore } from "@/stores/PatternStore";
import { useProductStore } from "@/stores/ProductStore";

export default {

    name: 'PatternPageView',

    components: {
        PatternCreateForm,
        ProductCreateForm,
        ProductItem,
    },

    data() {
        return {
            uuid: this.$route.params.pattern_uuid,
        }
    },

    computed: {
        ...mapState( usePatternStore, {
            pattern: 'getPattern',
            isPatternFetching: 'isPatternFetching',

            authors: 'getAuthors',
            tags: 'getTags',

            isPatternEditModalOpened: 'isModalEditOpened',
        } ),
        ...mapState( useProductStore, {
            products: 'getProducts',

            isProductsFetching: 'isProductsFetching',

            isProductCreateModalOpened: 'isModalCreateOpened',
        } ),
    },

    methods: {
        ...mapActions( usePatternStore, [
            'fetchPattern',
            'showPatternModal',
            'hidePatternModal',
        ] ),
        ...mapActions( useProductStore, [
            'fetchProducts',
            'showProductModal',
            'hideProductModal',
        ] ),

        getImage() {
            return (devMode)
                ? 'https://via.placeholder.com/900x600'
                : `${ odbUrl }/storage/catalog/patterns/${ this.pattern.uuid }/thumb.jpg`
        },
        getDate( date ) {
            return this.$moment( date ).format( 'DD.MM.YYYY HH:mm' )
        },
        getRelativeDate( date ) {
            return this.$moment( date ).fromNow()
        },
        getDateWithRelative( date ) {
            return this.$moment( date ).format( 'DD.MM.YYYY HH:mm' ) + '<br>' + this.$moment( date ).fromNow()
        },
        getColor( colors ) {
            return helper.getPaletteColor( colors )
        }

    },

    async created() {
        await this.fetchPattern( this.uuid )
        await this.fetchProducts( this.uuid )
    },

}

</script>
