const ERROR_TEXT = {
	IMAGE_REQUIRED: 'Основне зображення не вибрано',
	NAME_LENGTH: 'Довжина назви менша 2 символів',
	VERSION_REQUIRED: 'Версія товару не вказана',
	TYPE_REQUIRED: 'Тип товару не вказаний',
	CATEGORY_REQUIRED: 'Категорія не вказана',
	PALETTE_REQUIRED: 'Відтінок не вказаний',
	MODS_REQUIRED: 'Вказано менше двох модифікацій',
	DESC_REQUIRED: 'Відсутній опис',

	// FILE_LABEL_REQUIRED: '',
	FILE_XSD_REQUIRED: 'XSD-файл відсутній',
	FILE_PSD_REQUIRED: 'PSD-файл відсутній',
	FILE_RTF_REQUIRED: 'RTF-файл відсутній',
	FILE_RTF_NOT_VALID: 'Структура RTF-файлу пошкоджена',
}

exports.createProductRequest = req => {
	const errors = {}

	if ( req.image.length === 0 ) {
		errors.image = ERROR_TEXT.IMAGE_REQUIRED
	}

	if ( req.name.length < 2 ) {
		errors.name = ERROR_TEXT.NAME_LENGTH
	}

	if ( req.version.length === 0 ) {
		errors.version = ERROR_TEXT.VERSION_REQUIRED
	}

	if ( req.product_type_id.length === 0 ) {
		errors.type = ERROR_TEXT.TYPE_REQUIRED
	}

	if ( req.category_id === undefined || req.category_id?.length === 0) {
		errors.category = ERROR_TEXT.CATEGORY_REQUIRED
	}

	if ( req.palette_id.length === 0 ) {
		errors.palette = ERROR_TEXT.PALETTE_REQUIRED
	}

	if ( Object.keys( req.mods ).length < 2 ) {
		errors.mods = ERROR_TEXT.MODS_REQUIRED
	}

	if ( req.xsd.length === 0 ) {
		errors.xsd = ERROR_TEXT.FILE_XSD_REQUIRED
	}

	if ( req.psd.length === 0 ) {
		errors.psd = ERROR_TEXT.FILE_PSD_REQUIRED
	}

	if ( req.rtf.length === 0 ) {
		errors.rtf = ERROR_TEXT.FILE_RTF_REQUIRED
	} else {
		const reader = new FileReader()
		reader.readAsText( req.rtf, "UTF-8" )

		reader.onload = e => {
			if ( e.target.result.split( '\\page' ).length !== 2 ) {
				errors.rtf = ERROR_TEXT.FILE_RTF_NOT_VALID
			}
		}
	}

	if ( req.desc.length === 0 ) {
		errors.desc = ERROR_TEXT.DESC_REQUIRED
	}

	return errors
}

exports.updateProductRequest = req => {
	const errors = {}

	if ( req.name.length < 2 ) {
		errors.name = ERROR_TEXT.NAME_LENGTH
	}

	if ( req.rtf?.length > 0 ) {
		const reader = new FileReader()
		reader.readAsText( req.rtf, "UTF-8" )

		reader.onload = e => {
			if ( e.target.result.split( '\\page' ).length !== 2 ) {
				errors.rtf = ERROR_TEXT.FILE_RTF_NOT_VALID
			}
		}
	}

	return errors
}
