<template>
    <div class="w-full">
        <div>Модифікації</div>

        <div class="product-mods flex flex-wrap">
            <ul class="w-full grid grid-cols-2 text-sm">
                <li v-for="category in mods" :key="category.id">
                    <div class="w-full mb-3">
						<span v-if="category.name">
							{{ category.name }}
							<span v-if="category.desc" class="text-gray-400">{{ category.desc }}</span>
						</span>

                        <ul class="w-full flex flex-wrap justify-start gap-1">
                            <li>
                                <div
                                    class="p-1 w-6 text-center text-gray-400 cursor-pointer font-semibold hover:text-red-500"
                                    @click="clear(category.slug)"
                                >
                                    &#10005;
                                </div>
                            </li>
                            <li v-for="mod in category.mods" :key="mod.id">
                                <input type="radio"
                                       v-model="sel[category.slug]"
                                       :id="`ProductCreateMod${mod.id}`"
                                       :name="category.slug"
                                       :value="mod.id"
                                       :checked="selectedArr.includes(mod.id)"
                                       class="hidden peer"
                                       @change="select"
                                >
                                <label :for="`ProductCreateMod${mod.id}`"
                                       class="block p-1 min-w-[3rem] text-center text-gray-400 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-amber-400 peer-checked:bg-amber-400 peer-checked:text-gray-700 font-semibold hover:text-gray-600 hover:bg-gray-100">
                                    {{ mod.name }}
                                </label>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>

        </div>

        <div v-show="error.length > 0" class="text-red-500">{{ error }}</div>

    </div>
</template>

<script>

export default {

    name: 'ProductModsPicker',

    emits: [ 'update:modelValue' ],

    props: {
        selected: { type: Array, default: () => [] },
        mods: Array,
        error: { type: String, default: '' },
    },

    data() {
        return {
            selectedArr: [],
            sel: {
                width: 22,
                height: 28,
            },
        }
    },

    methods: {
        select() {
            this.emit()
        },

        clear( key ) {
            delete this.sel[key]
            this.emit()
        },

        emit() {
            this.$emit( 'update:modelValue', Object.values(this.sel) )
        }

    },

    watch: {
        selected(newValue) {
            this.selectedArr = newValue.map(m => m.id)
            newValue.forEach(m => {
                this.sel[m.category.slug] = m.id
            })
            this.emit()
        },
    },

    mounted() {
        this.selectedArr = this.selected
    }
}

</script>
