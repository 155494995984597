<template>
    <v-page-loader :loading="isPatternsFetching"/>

    <v-page-head title="Орнаменти">
        <template #buttons-r>
            <v-button icon="reload"
                      @click="fetchPatterns()"
            />
            <v-button icon="plus"
                      @click="showPatternModal('create')"
            />
        </template>
    </v-page-head>

    <div class="max-w-screen p-4 mx-auto grid gap-6 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-5 text-gray-500">
        <PatternItem
            v-for="pattern in patterns"
            :key="pattern.id"
            :pattern="pattern"
        />
    </div>

    <v-modal v-show="isPatternCreateModalOpened"
             @hide="hidePatternModal('create')"
             w="w-full md:w-4/5"
             title="Новий орнамент"
    >
        <PatternCreateForm/>
    </v-modal>

</template>

<script>
import { mapActions, mapState } from "pinia";

import PatternItem from '@/components/Pattern/PatternItem'
import PatternCreateForm from '@/components/Pattern/forms/PatternCreateForm'

import { usePatternStore } from "@/stores/PatternStore";

export default {

    name: 'PatternListView',

    components: {
        PatternItem,
        PatternCreateForm,
    },

    computed: {
        ...mapState( usePatternStore, {
            patterns: 'getPatterns',
            isPatternsFetching: 'isPatternsFetching',

            isPatternCreateModalOpened: 'isModalCreateOpened',
        } )
    },

    methods: {
        ...mapActions( usePatternStore, [
            'fetchPatterns',
            'showPatternModal',
            'hidePatternModal',
        ] ),
    },

    async created() {
        await this.fetchPatterns()
    },
}

</script>
