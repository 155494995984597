<template>
    <div class="w-full mb-3">

        <label v-if="label" :for="id" class="w-full flex justify-left space-x-2">

            <span v-if="label">{{ label }}</span>

        </label>

        <div class="py-2 border-4 border-dashed relative hover:border-gray-400 text-center text-gray-300 rounded-lg">
            <mdicon class="block my-4 flex justify-center" v-if="icon" :name="icon" :size="iconSize"/>
            <span class="font-bold">{{ label }}</span>
            <input @change="read($event)" :id="id" type="file" :name="name" :accept="accept" class="w-full h-full absolute top-0 left-0 opacity-0 cursor-pointer" :placeholder="label" :required="required" />
        </div>

        <div class="text-center">
            <span v-if="size > 0">{{ size }} Mb</span>
        </div>

		<div v-show="error.length > 0" class="text-red-500">{{ error }}</div>
    </div>
</template>

<script>
export default {

    name: 'v-input-file',

    emits: ['update:modelValue'],

    props: {
        label: String,
        icon: String,
        iconSize: { type: [String, Number], default: 24 },
        id: String,
        name: { type: String, required: true },
        modelValue: { type: [String, Number], default: '' },
        required: { type: Boolean, default: true },
        formMode: { type: String, default: 'create' },
        accept: { type: String, default: 'file/*' },
		error: { type: String, default: '' },
    },

    methods: {
        read(event) {
            this.size = (event.target.files[0].size / 1000000).toFixed(1)
            this.$emit('update:modelValue', event.target.files[0])
        }
    },

    data() {
        return {
            size: 0,
        }
    }

}

</script>
