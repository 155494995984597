<template>
    <div class="max-w-screen p-4 mx-auto text-gray-600">

        <v-breadcrumbs :items="breadcrumbs" />

        <div class="flex justify-between">
            <h1 class="font-bold mr-4 text-2xl leading-loose">{{ title }}</h1>

            <div class="buttons mr-auto flex justify-between space-x-2">
                <slot name="ipp"></slot>
                <slot name="groupBy"></slot>
                <slot name="buttons-l"></slot>
            </div>

            <div class="buttons ml-auto flex justify-between space-x-2">
                <slot name="buttons-r"></slot>
            </div>

        </div>

    </div>
</template>

<script>

export default {

    name: 'v-page-head',

    props: ['title'],

    computed: {
        breadcrumbs() {
            return this.$route.matched
        }
    },

}

</script>
