import ApiRequestProvider from '@/providers/ApiRequestProvider'
import { loginRequest } from '@/requests/AuthRequests'

export default {

    async login( data ) {
        const validation = await loginRequest( data )
        if( validation.data.errors?.length > 0 ) return validation

        return ApiRequestProvider._post( '/auth/loginUser', data )
    },

    async logout() {
        return ApiRequestProvider._post( '/auth/logoutUser' )
    },

}
