import { fileOdb } from '@/config'

export default {
	isEmail( email ) {
		const regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
		return regex.test( email )
	},

	getPhone( phone ) {
		return phone.replace( /(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})/, "+$1 ($2) $3 $4 $5" )
	},

	getRandomInt( min = 0, max = 1, bothInclusive = true ) {
		return Math.floor( Math.random() * (max - min + Number( bothInclusive )) + min )
	},

	getRandomFloat( min = 0, max = 1, bothInclusive = true, fixed = 2 ) {
		return parseFloat( (Math.random() * (max - min + Number( bothInclusive )) + min).toFixed( fixed ) )
	},

	// Palette
	getPaletteColor( colors ) {
		if(colors) {
			let colorArr = colors.split( ',' );

			return (colorArr.length === 2)
				? `bg-gradient-to-r from-${colorArr[0]} to-${colorArr[1]} shadow-${colorArr[0]}`
				: `bg-gradient-to-r from-${colorArr[0]} via-${colorArr[1]} to-${colorArr[2]}`
		}
	},

	// Files
	getFileSize( item ) {
		let size = 0

		// Kilobytes
		if ( item.size >= 1000 ) size = (item.size / 1000).toFixed( 2 ) + ' Кб'
		// Megabytes
		if ( item.size >= 1000000 ) size = (item.size / 1000000).toFixed( 2 ) + ' Мб'
		// Gigabytes
		if ( item.size >= 1000000000 ) size = (item.size / 1000000000).toFixed( 2 ) + ' Гб'

		return size
	},

	//
	getLocalNetworkPathLink( path ) {
		return `${fileOdb}${path}`
	},
}

