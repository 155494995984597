<template>
    <form @submit.prevent="onSubmit()" id="UserLoginForm">

        <div class="mx-auto">

            <!-- <label for="UserLoginFormEmail" class="form-label">Email</label> -->
            <input id="UserLoginFormEmail" type="email" v-model="values.email"
                   class="w-full p-4 border border-b-0 border-gray-300 rounded-t-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                   placeholder="Email" required>

            <div class="h-px bg-gray-200"></div>

            <!-- <label for="UserLoginFormPassword" class="form-label">Пароль</label> -->
            <input id="UserLoginFormPassword" type="password" v-model="values.password"
                   class="w-full p-4 border border-t-0 border-gray-300 rounded-b-lg focus:outline-none focus:ring-2 focus:ring-gray-300"
                   placeholder="Пароль" required>

            <button type="submit" class="w-full bg-amber-300 mt-5 p-4 rounded-lg">Увійти</button>

        </div>

    </form>
</template>

<script>
import { useAuthStore } from '@/stores'

export default {

    name: 'AuthForm',

    data() {
        return {
            values: {
                email: '',
                password: ''
            },
        }
    },

    methods: {
        async onSubmit() {
            const authStore = useAuthStore()

            await authStore.login( this.values )
        }
    }

}

</script>
