import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'

import '@/assets/css/app.css'

import router from './router'

import mdiVue from 'mdi-vue/v3'
import * as mdijs from '@mdi/js'

import moment from 'moment'
import 'moment/locale/uk'
moment.locale( 'uk' )

import components from '@/components/_ui'

const app = createApp( App )
const pinia = createPinia()

// Register global components
components.forEach(component => {
    app.component( component.name, component )
})

app.config.globalProperties.$moment = moment

app.use( router )
app.use( mdiVue, { icons: mdijs } )
app.use( pinia )
app.mount( '#app' )
